import { FlatList, StyleSheet, Text, View } from 'react-native';
import { useState, useEffect } from 'react';
import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { ImageBackground } from 'react-native';
import { appStyles } from '../styles/app_styles';
import {  Dimensions } from 'react-native'
import { Platform } from 'react-native';
import { fetchPlayerScores } from '../../repository/PlayerScoreDAL';
const { width, height } = Dimensions.get('window')

const ScoreboardScreen = () => {

    const [playerScores, setPlayerScores] = useState([]);

    useEffect(() => {
        fetchPlayerScores().then(res=>setPlayerScores(res));
      }, []);
    

  return (

    <ImageBackground style={ appStyles.imgBackground } 
    resizeMode='cover' 
    source={require('../../../assets//background_img/background_hill.jpg')}
    blurRadius={2}>


<View style={styles.container}>
      <FlatList
        data={ playerScores }
        renderItem={({item}) =>
        <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>  
            <Text style={styles.placeStyle}>{item.place}</Text>
            <Text style={styles.nameStyle}>{item.playerName}</Text>
            <Text style={styles.scoreStyle}>{item.playerScore}</Text>
        </View>}
      />
    </View>
<StatusBar style="auto" />

  
    </ImageBackground>
    
  );
}

const styles = StyleSheet.create({
    container: {
      //  justifyContent: 'center',
    alignItems: 'center',
     flex: 1,
     paddingTop: 75
    },
    item: {
      padding: 10,
      fontSize: 18,
      height: 44,
    },
    placeStyle: { // Edit to text
        //        flex: 1,
                width: 50,
                margin: 2,
                padding: 10,
                textAlign: 'center',
                color: '#FFF',
                fontSize: 16,
                backgroundColor: 'rgba(0,0,0,0.6)',
    },
    nameStyle: { // Edit to text
        //        flex: 1,
                margin: 2,
                width: 200,
                padding: 10,
                textAlign: 'center',
                color: '#FFF',
                fontSize: 16,
                backgroundColor: 'rgba(0,0,0,0.6)',
    },
    scoreStyle: { // Edit to text
        //        flex: 1,
                margin: 2,
                width: 100,
                padding: 10,
                textAlign: 'center',
                color: '#FFF',
                fontSize: 16,
                backgroundColor: 'rgba(0,0,0,0.6)',
    },
    width: width,
  });
  

export default ScoreboardScreen;
import { StyleSheet, Dimensions } from 'react-native'
import { Platform } from 'react-native';
const { width, height } = Dimensions.get('window')

const optionStyle = {
  flex: 0,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingVertical: 2,
  paddingHorizontal: 10,
  borderBottomWidth: 0.0,
  borderBottomColor: '#393433'
}

const optionTextStyle = {
  paddingVertical: 5,
  flex: 1,
  textAlign: 'center',
  color: '#FFF',
  fontSize: 22,
  backgroundColor: 'rgba(0,0,0,0.6)',
}

export default StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    //backgroundColor: 'rgba(0,0,0,0.85)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleTextStyle: {
    flex: 0,
    color: '#fff',
    fontSize: 20,
    marginBottom: 15
  },
   flatListStyle:{
    paddingTop:Platform.OS === 'web' ? 20 : 0
  },
  listContainer: {
    flex: 1,
    width: width,
    //maxHeight: height*.95,
    height: height*.90,
   // backgroundColor: '#fff',
    borderRadius: 0,
    marginBottom: 0
  },
  filterTextInputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  filterTextInput: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    flex: 0,
    height: 50,
    color: '#FFF',
  },
  categoryStyle: {
    ...optionStyle
  },
  categoryTextStyle: {
    ...optionTextStyle,
    fontStyle: 'italic',
    color: '#999',
    fontSize: 16
  },
  optionStyle: {
    ...optionStyle
  },
  optionStyleLastChild: {
    borderBottomWidth: 0
  },
  optionTextStyle: {
    ...optionTextStyle
  },
  selectedOptionStyle: {
    ...optionStyle
  },
  selectedOptionStyleLastChild: {
    borderBottomWidth: 0
  },
  selectedOptionTextStyle: {
    ...optionTextStyle,
    fontWeight: '700'
  },
  noResults: {
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  noResultsText: {
    flex: 1,
    textAlign: 'center',
    color: '#ccc',
    fontStyle: 'italic',
    fontSize: 22
  },
  cancelContainer: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelButton: {
    flex: 0,
    backgroundColor: '#999',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10
  },
  cancelButtonText: {
    textAlign: 'center',
    fontSize: 18
  },
})

import React from 'react';
import { useState, useEffect, useFocusState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Button, Image, Platform, TouchableOpacity, Animated } from 'react-native';
import FitImage from 'react-native-fit-image';
import {useSelector} from 'react-redux';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'
import { Dimensions } from 'react-native';
import { ImageBackground } from 'react-native';
import { appStyles } from '../styles/app_styles';
import Elevations from 'react-native-elevation';

const AboutScreen = ({ navigation }) => {
    const content =  useSelector(state => state.gameState.curr_content );

    return(

    <ImageBackground style={ appStyles.imgBackground } 
    resizeMode='cover' 
    source={require('../../../assets//background_img/background_hill.jpg')}
    blurRadius={2}>


    <View style={styles.container}>
      {content.lang === 'dk'?
        <Text style={styles.textStyle}>  {"\n"}  <Text style={{fontSize: 26}}>Velkommen til MATKEND </Text> {"\n"}{"\n"}{"\n"}
          Bemærk, at denne app er under udvikling og indeholder et begrænset antal køretøjer. Der arbejdes for at udvide app'en. Hvis du har nogen gode forslag, så må du meget gerne skriv til mig.  {"\n"}{"\n"}  {"\n"} 
           En særlig tak til <Text style={{fontWeight: 'bold'}}>Funder Jensen </Text>for at booste desginet og at sætte den rigtige stemmning.  {"\n"} {"\n"} {"\n"} God fornøjelse! {"\n"} Armandas Rokas {"\n"}  {"\n"} Email: armandas.rokas@gmail.com   {"\n"}</Text>
    : 
    <Text style={styles.textStyle}>  {"\n"}  <Text style={{fontSize: 26}}> Welcome to MATKEND </Text> {"\n"}{"\n"}{"\n"}
    Here you can practice your knowledge about military vehicles. {"\n"} Note that, the app is still under development. {"\n"} {"\n"} {"\n"} Good luck! {"\n"}</Text>
    }
    <StatusBar style="auto" />

    </View>
    </ImageBackground>
  );
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      //backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textStyle: { // Edit to text
//        flex: 1,
        padding: 10,
        textAlign: 'center',
        color: '#FFF',
        fontSize: 16,
        backgroundColor: 'rgba(0,0,0,0.6)',
    },
  });

export default AboutScreen;
import { firebase } from '@firebase/app';
// import firestore from '@react-native-firebase/firestore';
import { getFirestore, setDoc, doc } from 'firebase/firestore'; // Somehow crashes if the import is removed
import { firebaseDB } from './FirebaseService';
import initializeFirebase from './FirebaseService';

export const vehicles = [];
    

export const fetchVehiclesFromFirebase = async () => {
    if(vehicles.length > 0){
        return true;
    }
    // Initialize Firebase
    if(firebaseDB === undefined){
      await initializeFirebase();
    }

    let vehicles_querySnapshot; 
//    try{  // Somehow execution still continues after a catch. 
        vehicles_querySnapshot = await firebaseDB.collection('vehicles').get();
    // } catch (e) {
    // }
    
    
    vehicles_querySnapshot.forEach(documentSnapshot => {
        let vehicle = documentSnapshot.data();
        let newVehicle = {}
        newVehicle.id_vehicle =  documentSnapshot.id;
        newVehicle.name_vehicle = vehicle.VehicleName;
        newVehicle.img_vehicle = "https://firebasestorage.googleapis.com/v0/b/matkend-76800.appspot.com/o/vehicles%2F"+vehicle.VehicleImage+"?alt=media";
        newVehicle.level_vehicle = vehicle.VehicleLevel;
        vehicles.push(newVehicle)
    }); 

        // WORKING older
        // await db.collection('vehicles')
        // .get()
        // .then(querySnapshot => {
        //   querySnapshot.forEach(documentSnapshot => {
        //       let vehicle = documentSnapshot.data();
        //       let newVehicle = {}
        //       newVehicle.id_vehicle =  documentSnapshot.id;
        //       newVehicle.name_vehicle = vehicle.VehicleName;
        //       newVehicle.img_vehicle = vehicle.VehicleImage;
        //       newVehicle.level_vehicle = vehicle.VehicleLevel;
        //       vehicles.push(newVehicle)
        //      // {id_vehicle:0, type_vehicle:'IKK', name_vehicle: 'CV90', img_vehicle: require('../../img/cv9035.jpg'), level_vehicle: LEVEL_1 }, 
        //   });
        // })

        if(vehicles.length == 0){
            throw Error("Vehicles couldn't be fetched") 
        }

    return true;
  }    
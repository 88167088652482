import React from 'react';
import { useState, useEffect, useFocusState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, Platform, TouchableOpacity, Animated } from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {Input, Button} from 'react-native-elements';
import {chooseGameLevel, updateScore, changeLanguage} from '../../controller/gameState/actions/GameStateAction'
import {LEVEL_1, LEVEL_2, ALL_VEHICLES} from '../../controller/VehicleController'
import { updateVehicle } from '../../controller/gameState/actions/GameStateAction';
//import { Text } from 'react-native-elements';
import { human, material, materialColors, robotoWeights } from 'react-native-typography'
import {resetAlreadyFetchedVehicles} from '../../controller/VehicleController'
import { ImageBackground } from 'react-native';
import { appStyles } from '../styles/app_styles';
import { Dimensions } from 'react-native';
import {  fetchVehiclesFromFirebase } from '../../repository/VechileFirestoreDAL';
import HidableView from '../result/HidableView';
import { dk_content, en_content } from '../../repository/Contents';


const StartScreen = ({ navigation }) => {
  // Fetch vehicles from firebase as the first step and do not allow to play a game, before all vehicles are fetched.
  const [isBtnHidden, toggleBtn] = useState(true);
  const [isErrorHidden, toggleError] = useState(true);
  fetchVehiclesFromFirebase().then(()=>toggleBtn(false)) 
                             .catch(()=>toggleError(false));

  //  navigation.setOptions({
//    headerShown: false
 // });

  const dispatch = useDispatch();

    const content =  useSelector(state => state.gameState.curr_content );


    React.useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
          dispatch(updateScore(0));
          resetAlreadyFetchedVehicles();
      });
  
      // Return the function to unsubscribe from the event so it gets removed on unmount
      return unsubscribe;
    }, [navigation]);

//  const curr_vehicle =  useSelector(state => state.gameState.curr_vehicle )
    const languageBtnHandler = () => {
        if(content.lang === 'en'){
          dispatch(changeLanguage(dk_content))
        } else if (content.lang === 'dk'){
          dispatch(changeLanguage(en_content))
        }
    }

const playButtonHandler = (trin) => {


  
    switch(trin){
        case LEVEL_1:
            console.log(1);
            dispatch(chooseGameLevel(LEVEL_1));
            break;
        case LEVEL_2:
            console.log(2);
            dispatch(chooseGameLevel(LEVEL_2));
            break;
        case ALL_VEHICLES:
            console.log('All vehicles');
            dispatch(chooseGameLevel(ALL_VEHICLES));
            break;
            
}
    //dispatch(updateVehicle());
    navigation.navigate('Question');
     dispatch(updateVehicle());
   }

  return(
    <ImageBackground style={ appStyles.imgBackground } 
                 resizeMode='cover' 
                 source={require('../../../assets/background_img/background_hill.jpg')}>
    
    <View style={styles.container}>
          
          <View style={styles.headerContainer}>
            <View 
                  style={styles.headerButton}
                >
              <Button title={content.lang === 'dk'? 'EN': 'DK'}
                  titleStyle={styles.roundBtnTitleStyle}
                  buttonStyle={styles.roundButton}
                  onPress={languageBtnHandler} />
            </View>
            <View 
              //style={{marginBottom:100}}
              // style={{position: 'absolute', top: 60}}
              >
              <Text style={styles.title} >MATKEND</Text>
          </View>
 

          </View>
         
        <HidableView hide={isErrorHidden} > 
            {/* <View style={{marginBottom:200,backgroundColor:'#f47373',width: Dimensions.get('window').width*.70}}>  </View> */}
            <Text style={[styles.error,{marginBottom:200,backgroundColor:'#f47373',width: Dimensions.get('window').width*.70}]}>{"App'en kunne ikke hente billeder. Prøv at kontrollere din internet forbindelse og genstarte app'en."}</Text>
        </HidableView>
        <HidableView hide={isBtnHidden} > 
        <Button title={content.btn_play}
                        titleStyle={appStyles.btnTitleStyle}
                        buttonStyle={appStyles.buttonStyle}
                        onPress={() => navigation.navigate('ChooseLevel')} />
          <Button title={content.resultsBtn}
                        titleStyle={appStyles.btnTitleStyle}
                        buttonStyle={appStyles.buttonStyle}
                        onPress={() => navigation.navigate('Scoreboard')} />
          {/* <Button title={content.aboutBtn}
                        titleStyle={appStyles.btnTitleStyle}
                        buttonStyle={appStyles.buttonStyle}
                        onPress={() => navigation.navigate('About')} 
                      />   */}
        </HidableView>

<StatusBar style="auto" />
    </View>
    </ImageBackground>
  );
}

const { width, fontScale } = Dimensions.get("window");


const styles = StyleSheet.create({
    container: {
      flex: 1,
  //    backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerContainer: {
      position: 'absolute', 
      top: 60, 
      width: width,
      flex:1,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
 /*   title: {
        fontSize: 30,
        fontWeight: "bold",

      },*/
      // https://github.com/hectahertz/react-native-typography
      title: {
      //  ...material.display4,
        ...robotoWeights.thin,
      //  color: materialColors.blackPrimary,
      //  fontSize: 120,
        fontSize: 60 / fontScale,
     //   textDecorationLine: 'underline',
        color:'#fff',
      },
      error: {
         padding: 10,
       // ...material.display4,
        ...robotoWeights.thin,
        fontSize: 18,
        color:'#fff'
      },
      roundButton: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
       borderRadius: 100,
        backgroundColor: 'black',
      },
      roundBtnTitleStyle: {
        //  ...material.display4,
        // ...robotoWeights.medium,
          color: "white",
          fontSize: 16,
        },
        headerButton: {
        right: 20, 
        alignSelf: 'flex-end',
        // ...Platform.select({
        //   android: {
        //     maxHeight: 20,
        //   },
        // })
      },
  });

export default StartScreen;
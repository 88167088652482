//import {getVechileByID,getAllVehicles, getNumberOfVehicles, ALL_VEHICLES} from '../repository/VechileDAL'
import { vehicles } from '../repository/VechileFirestoreDAL';

export const LEVEL_1 = 'LEVEL_1';
export const LEVEL_2 = 'LEVEL_2';
export const ALL_VEHICLES = 'ALL_VEHICLES';


var alreadyFetchedVehicles = []; // By real id's. Must be reseted if the StartScreen is entered. 


export const resetAlreadyFetchedVehicles = () => {
    alreadyFetchedVehicles = [];
}

/* Keep tracks of already fetched vehicles. 
*  If a returned vehicle already was fetched before, try again.
*  Clean the list, when all vehicles have been fetched. 
*/
export const getRandomVehicle = (level) => {
    const numberOFVehicles = getNumberOfVehicles(level);
    const vehiclesIDbyLevel =  getAllVehicles(level).map(v=> v.id_vehicle);
    let isFetchedBefore = true;
    let vehicle;
    do {
        //vehicle = getVechileByID(getRandomInt(numberOFVehicles));
  //      const randomIndex= getRandomInt(numberOFVehicles)
    //    console.log(randomIndex);
      //  console.log("Current random id: " + vehiclesIDbyLevel[randomIndex] );
        vehicle = getVechileByID(vehiclesIDbyLevel[getRandomInt(numberOFVehicles)]);
        if(level != vehicle.level_vehicle ){  // level != ALL_VEHICLES  && 
                console.log('In the loop')
                continue;
        }

        if(!alreadyFetchedVehicles.includes(vehicle.id_vehicle)){
            isFetchedBefore=false;
            alreadyFetchedVehicles.push(vehicle.id_vehicle);
            if(alreadyFetchedVehicles.length >= numberOFVehicles){
                alreadyFetchedVehicles = [];
            }
        }
    } while (isFetchedBefore);

    return vehicle;
}

export const getAllVehiclesController = (level) => getAllVehicles(level); 

function getRandomInt(max) {
   return Math.floor(Math.random() * max);
}


export const getVechileByID = id_vehicle => vehicles.find(v => v.id_vehicle === id_vehicle);

export const getAllVehicles = (level) =>{
    if(typeof level === 'undefined') {
        return vehicles;
    } else {
        return vehicles.filter(v => v.level_vehicle === level);
    }
} 

export const getNumberOfVehicles = (level) => {
    if(typeof level === 'undefined') {
        return vehicles.length;
    }
    
    console.log("level in getNumberOfVehicles: " + level);
    let counter = 0;
    vehicles.forEach((v,i) =>  {if(v.level_vehicle === level) counter++ });
    console.log('counter: ' + counter);
    return counter;
    
} 

import { LEVEL_1,LEVEL_2 } from '../../VehicleController';
import {getRandomVehicle} from '../../VehicleController'
import { ANSWER_QUESTION, CHOOSE_GAME_LEVEL, UPDATE_VEHICLE, UPDATE_BACKGROUND, UPDATE_SCORE, CHANGE_LANGUAGE } from '../actions/GameStateAction';
import { getRandomBackground } from '../../../repository/BackgroundImageDAL';
import { dk_content,en_content } from '../../../repository/Contents';

const initialState = {
    chosenLevel: LEVEL_1,
    //curr_vehicle: getRandomVehicle(LEVEL_1),
    curr_vehicle: null,
    isAnswerCorrect: true,
    background_image: getRandomBackground(),
    curr_score: 0,
    curr_content: dk_content
    // total correct
};

const gameStateReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_VEHICLE:
            return {...state, curr_vehicle: getRandomVehicle(state.chosenLevel)};
          //return {...state};
        case ANSWER_QUESTION:
            return {...state, isAnswerCorrect: action.isCorrect};
        case CHOOSE_GAME_LEVEL:
                return {...state, chosenLevel: action.chosenLevel};
        case UPDATE_BACKGROUND:
                return {...state, background_image: getRandomBackground()};
        case UPDATE_SCORE:
                return {...state, curr_score: action.curr_score};
        case CHANGE_LANGUAGE:
                return {...state, curr_content: action.curr_content};
        default:
            return {...state};
    }
}

export default gameStateReducer;  
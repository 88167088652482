import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import {useSelector, useDispatch} from 'react-redux';
import {validateAnswer} from '../../controller/GameLogic'
import {answerQuestion,updateScore} from '../../controller/gameState/actions/GameStateAction'
import {getAllVehiclesController} from '../../controller/VehicleController'
import FilterPicker from './FilterPicker';
import { Dimensions } from 'react-native';
import { ImageBackground } from 'react-native';
import { appStyles } from '../styles/app_styles';
import { calculateScore } from '../../controller/GameLogic';

//import ModalFilterPicker from 'react-native-modal-filter-picker'
const { width, height } = Dimensions.get('window')

const AnswerScreen = ({ route, navigation }) => {
  

  const curr_vehicle =  useSelector(state => state.gameState.curr_vehicle );
  const chosenLevel =  useSelector(state => state.gameState.chosenLevel );
  const curr_background =  useSelector(state => state.gameState.background_image )
  const curr_score =  useSelector(state => state.gameState.curr_score );
  const chosen_level =  useSelector(state => state.gameState.chosenLevel );
  const content =  useSelector(state => state.gameState.curr_content );

 const onSelect = (picked) => {
    const isCorrect = validateAnswer(curr_vehicle.name_vehicle, picked.label);
    const updated_score = calculateScore(curr_score,isCorrect,chosen_level) 
    dispatch(updateScore(updated_score));
    dispatch(answerQuestion(isCorrect));
    navigation.navigate('Result');
 }
 const onCancel = () => {};
  const dispatch = useDispatch();

  const all_vehicles =  getAllVehiclesController(chosenLevel).map(v=> v.name_vehicle);
  const all_vehicles_no_duplicates = [... new Set(all_vehicles)];
  const options = all_vehicles_no_duplicates.map(
    vehicle => ({key: vehicle.toLowerCase(), label: vehicle})).sort((a, b) => a.key.localeCompare(b.key))


  return(
    <ImageBackground style={ appStyles.imgBackground } 
    resizeMode='cover' 
    source={curr_background.img_path}
    blurRadius={2}>

    <View style={styles.container}>
      <StatusBar style="auto" />
   {/*   <Text style={styles.chooseAnswer}>Vælg svaret</Text> */}
      <View style={styles.filterPickerContainer}>

      <FilterPicker
          options={options}
          onSelect={onSelect}
          options={options}
          onCancel={onCancel}
          placeholderText={content.filterPlaceholder}
          
        />
        
      </View>
    </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
    
    container: {
      flex: 1,
  //    backgroundColor: '#fff',
      alignItems: 'center',
     // justifyContent: 'center',
    },
    chooseAnswer: {
      fontSize: 30,
      fontWeight: "bold",
      marginTop: 0,
     // marginBottom: 0,
    },
    filterPickerContainer: {
      //marginBottom: 0,
      //position: 'absolute',
      //justifyContent: 'flex-end',
      marginTop: height*0.10,
    },
    input: {
      width: 80,
      height: 40,
      margin: 12,
      borderWidth: 1,
    },
  });

export default AnswerScreen;
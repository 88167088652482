import React from 'react';
import {View} from 'react-native';

const HidableView = props => {
    const { children, hide, style } = props;
    if (hide) {
      return null;
    }
    return (
      <View style={style}>   
        { children }
      </View>
    );
}

// <View {...this.props} style={style}>

export default HidableView;
const backgroundImages = [
    {img_path: require('../../assets/background_img/background_house.jpg')},
    {img_path: require('../../assets/background_img/background_city.jpg')},
    {img_path: require('../../assets/background_img/background_forest.jpg')},
    {img_path: require('../../assets/background_img/background_field.jpg')},
  //  {img_path: require('../../assets/background_img/background_car.jpg')},
    {img_path: require('../../assets/background_img/background_hill.jpg')},
]

export const getRandomBackground = () => {
    return backgroundImages[getRandomInt(backgroundImages.length)];
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
 }
 
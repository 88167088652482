import 'react-native-gesture-handler';
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// Redux
import {createStore, combineReducers} from 'redux';
import gameStateReducer from './src/controller/gameState/reducers/GameStateReducer';
import {Provider} from 'react-redux'
import HeaderBackButton from '@react-navigation/stack'


// Components
import QuestionScreen from './src/view/question/QuestionScreen'
import AnswerScreen from './src/view/answer/AnswerScreen'
import ResultScreen from './src/view/result/ResultScreen'
import StartScreen from './src/view/start/StartScreen'
import AboutScreen from './src/view/about/AboutScreen'
import EndgameScreen from './src/view/endgame/EndgameScreen'
import ScoreboardScreen from './src/view/scoreboard/ScoreboardScreen';
import { material, robotoWeights } from 'react-native-typography'
import ChooseLevelScreen from './src/view/chooseLevelScreen/ChooseLevelScreen';
import {useSelector, useDispatch} from 'react-redux';

const rootReducer = combineReducers({
  gameState : gameStateReducer
});

const store = createStore(rootReducer);

const Stack = createStackNavigator();

export default function App(){
 
  return (
    <Provider store={store}>
        < NavigationWrapper />
    </Provider>
  );
}

function NavigationWrapper () {
  const content =  useSelector(state => state.gameState.curr_content );
  return (
          <NavigationContainer>
          <Stack.Navigator initialRouteName="Start"
          screenOptions={{
        //   headerShown: true,
            headerTransparent: true,
              headerTintColor: '#fff',
              headerTitleStyle: {
                  ...material.display4,
                  ...robotoWeights.light,
                  color: "white",
                  fontSize: 24,
              // fontWeight: 'bold'
              },
              headerTitleAlign: 'center'

          }}
          >
            <Stack.Screen 
                  options={{
            headerShown: false,

          }}
            name="Start" component={StartScreen} />
            <Stack.Screen name="Question" 
                          component={QuestionScreen}
                          options={{ title: content.navigator_text.question_title,headerLeft: null }} />
            <Stack.Screen name="Answer" 
                          component={AnswerScreen} 
                          options={{ title: content.navigator_text.answer_title,headerLeft: null }}/>
            <Stack.Screen name="Result" 
                          component={ResultScreen}
                          options={{
                            title: content.navigator_text.feedback_title,
                            headerLeft: null
                        /*   headerLeft: (props,route) => (
                              <HeaderBackButton
                                {...props}
                                onPress={() => {
                                  navigation.navigate('Start');
                                  console.log('Back');
                                }}
                              />
                            ),*/
                          }}
                          /* options={({navigation, route}) => ({
                            headerLeft: (props) => (
                              <HeaderBackButton
                                {...props}
                                onPress={() => navigation.navigate('Start')}
                              />
                            ),
                      })}*/
                    />
            <Stack.Screen name="About" 
                          component={AboutScreen}
                          options={{ title: content.navigator_text.about_title}} />
            <Stack.Screen name="Endgame" 
                          component={EndgameScreen}
                          options={{ title: content.navigator_text.endgame_title }} />
              <Stack.Screen name="Scoreboard" 
                          component={ScoreboardScreen}
                          options={{ title: content.navigator_text.scoreboard_title  }} />
              <Stack.Screen name="ChooseLevel" 
                          component={ChooseLevelScreen}
                          options={{ title: content.navigator_text.choose_level_title }} />
          </Stack.Navigator>
        </NavigationContainer>
  )
}

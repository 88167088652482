import { LEVEL_1, LEVEL_2 } from "./VehicleController";

export const validateAnswer = (curr_vehicle, curr_answer) => {
    const areEqual = curr_vehicle.toUpperCase() === curr_answer.toUpperCase();
    return areEqual;
}

export const calculateScore = (curr_score, curr_isCorrect, curr_level) => {
    if(curr_isCorrect) {
        console.log(curr_level)
        switch(curr_level){
            case LEVEL_1:
                return curr_score+2;
            case LEVEL_2:
                return curr_score+5;
        }
    } else {
        return curr_score-1;
    }
}
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const ANSWER_QUESTION = 'ANSWER_QUESTION'; 
export const CHOOSE_GAME_LEVEL = 'CHOOSE_GAME_LEVEL';
export const UPDATE_BACKGROUND = 'UPDATE_BACKGROUND';
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const updateVehicle = () => {
    return { type: UPDATE_VEHICLE};
}

export const answerQuestion = (isAnswerCorrect) => {
    return { type: ANSWER_QUESTION, isCorrect: isAnswerCorrect};
}

export const chooseGameLevel = (level) => {
    return { type: CHOOSE_GAME_LEVEL, chosenLevel: level};
}

export const updateBackground = () => {
    return { type: UPDATE_BACKGROUND};
}

export const updateScore = (score) => {
    return { type: UPDATE_SCORE, curr_score: score};
}

export const changeLanguage = (content) => {
    return { type: CHANGE_LANGUAGE, curr_content: content};
}
import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, Platform, TouchableOpacity, Animated } from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {Input, Button} from 'react-native-elements';
import {chooseGameLevel, updateScore} from '../../controller/gameState/actions/GameStateAction'
import {LEVEL_1, LEVEL_2, ALL_VEHICLES} from '../../controller/VehicleController'
import { updateVehicle } from '../../controller/gameState/actions/GameStateAction';
//import { Text } from 'react-native-elements';
import { material, robotoWeights } from 'react-native-typography'
import { ImageBackground } from 'react-native';
import { appStyles } from '../styles/app_styles';
import { Dimensions } from 'react-native';
import HidableView from '../result/HidableView';

const  ChooseLevelScreen = ({ navigation }) => {


  const dispatch = useDispatch();
  const content =  useSelector(state => state.gameState.curr_content );


const playButtonHandler = (trin) => {
  
    switch(trin){
        case LEVEL_1:
            console.log(1);
            dispatch(chooseGameLevel(LEVEL_1));
            break;
        case LEVEL_2:
            console.log(2);
            dispatch(chooseGameLevel(LEVEL_2));
            break;
        case ALL_VEHICLES:
            console.log('All vehicles');
            dispatch(chooseGameLevel(ALL_VEHICLES));
            break;
            
}
    navigation.navigate('Question');
     dispatch(updateVehicle());
   }

  return(
    <ImageBackground style={ appStyles.imgBackground } 
                 resizeMode='cover' 
                 source={require('../../../assets/background_img/background_hill.jpg')}>
    
    <View style={styles.container}>
    
          <Button title={content.level_1_Btn}
                        titleStyle={appStyles.btnTitleStyle}
                        buttonStyle={appStyles.buttonStyle}
                        onPress={() => playButtonHandler(LEVEL_1)} 
                      /> 
          <Button title={content.level_2_Btn}
                        titleStyle={appStyles.btnTitleStyle}
                        buttonStyle={appStyles.buttonStyle}
                        onPress={() => playButtonHandler(LEVEL_2)} 
                      /> 

<StatusBar style="auto" />
    </View>
    </ImageBackground>
  );
}

const { width, fontScale } = Dimensions.get("window");

const styles = StyleSheet.create({
    container: {
      flex: 1,
  //    backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
 /*   title: {
        fontSize: 30,
        fontWeight: "bold",

      },*/
      // https://github.com/hectahertz/react-native-typography
      title: {
        ...material.display4,
        ...robotoWeights.thin,
      //  color: materialColors.blackPrimary,
      //  fontSize: 120,
        fontSize: 60 / fontScale,
     //   textDecorationLine: 'underline',
        color:'#fff'
      },
      error: {
         padding: 10,
       // ...material.display4,
        ...robotoWeights.thin,
        fontSize: 18,
        color:'#fff'
      }
  });

export default ChooseLevelScreen;
import React from 'react';
import { useState, useEffect, useFocusState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Button, Image, Platform, TouchableOpacity, Animated } from 'react-native';
import FitImage from 'react-native-fit-image';
import {useSelector} from 'react-redux';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'
import { Dimensions } from 'react-native';
import { ImageBackground } from 'react-native';
import { appStyles } from '../styles/app_styles';
import Elevations from 'react-native-elevation';

const QuestionScreen = ({ navigation }) => {
  //const img_path = '../../../img/';
  //let curr_vehicle = getRandomVehicle();
  //let curr_vehicle_img_path = img_path + curr_vehicle.img_name; 

  const curr_vehicle =  useSelector(state => state.gameState.curr_vehicle )
  const curr_background =  useSelector(state => state.gameState.background_image )
//  console.log(curr_vehicle_redux);

//  const [curr_vehicle, setVehicle] = useState(getRandomVehicle())
//  const [curr_aspectRatio, setAspectRatio] = useState(0.6)



  /**  It counts but it works best with static 0.6
   
  let image_meta = Image.resolveAssetSource(curr_vehicle.img_vechile);
  console.log(image_meta.height);
  console.log(image_meta.width);

  const img_style = StyleSheet.create({
    fitImage: {
      resizeMode: 'contain',
      aspectRatio: image_meta.height/image_meta.width,
      flex: 1,
    },
  });
  **/
  const [seconds, setSeconds] = useState(10);
  const [loading, setLoading] = useState(true);
  const [isImageVisable, setIsImageVisable] = useState(true);
  const [desiredHeight, setDesiredHeight] = useState(0);

  const desiredWidth = Dimensions.get('window').width*.98;
  Image.getSize(curr_vehicle.img_vehicle, (width, height) => {
    setDesiredHeight(desiredWidth/ width*height)
  })


 // let timerId = setInterval(() => setSeconds(seconds - 1), 1000);
/*
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    //return () => clearInterval(interval);
  }, []);
*/

  useEffect(() => {
    const resetTimerOnFocus = navigation.addListener('focus', () => {
      setLoading(true)
      setIsImageVisable(true)
      //setSeconds(10);
    //  console.log('Hello')
      //timerId = setInterval(() => setSeconds(seconds - 1), 1000);
      setKey(prevKey => prevKey + 1);
    //  console.log(key);
    });

    return resetTimerOnFocus;
  }, [navigation]);
  


  
/*
  useEffect(() => {
    const stopTimerOnBlur = navigation.addListener('blur', () => {
      clearInterval(timerId);
    });
    return stopTimerOnBlur;
  }, [navigation]);
  */
/*
  useEffect(() => {
    if (seconds > 0) {
   //   setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
    //  setSeconds('Tiden er gået!')
      navigation.navigate('Answer')
    }
  });*/
//  useEffect(() => setTimeout(()=>{navigation.navigate('Answer') }, 5000));

  
const [key, setKey] = useState(0);

const timer = loading ? null: (  <CountdownCircleTimer
  onComplete={() => {
  //  curr_vehicle.img_vehicle = null
  //dispatch(setCurrVehicleImgNull());
  setIsImageVisable(false)
    setLoading(true)
    navigation.navigate('Answer')
         // return [true, 1500] // repeat animation in 1.5 seconds
        }}
    isPlaying
    duration={5}
    size={70}
    key={key}
    colors={[
      ['#004777', 0.4],
      ['#F7B801', 0.4],
      ['#A30000', 0.2],
    ]}
  >
    {({ remainingTime, animatedColor }) => (
      <Animated.Text style={{ color: animatedColor }}>
        {remainingTime}
      </Animated.Text>
    )}
  </CountdownCircleTimer>)

const curr_vehicle_image = isImageVisable ? (
<Image onLoad={() => setLoading(false)} style={[styles.fitImage_new,  {width: desiredWidth,
      height: desiredHeight}]}  source={{uri:curr_vehicle.img_vehicle}} />) : null;
  
 //useEffect( () => setKey(key + 1));
 //source={require('../../../assets/background_house.jpeg')
  return(

    <ImageBackground style={ appStyles.imgBackground } 
    resizeMode='cover' 
    source={curr_background.img_path}
    blurRadius={2}>


    <View style={styles.container}>

     

      
     {/* <TouchableOpacity onPress={() => navigation.navigate('Answer')}>*/}
   
        {/*<View style={styles.ContainerImageProfile} > */}
   {/* <View style={{position: 'absolute', top: 3, left: 3, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
   <Image style={[{width: desiredWidth,
      height: desiredHeight},styles.shadow  ]}      source={{uri:curr_vehicle.img_vehicle}} /> 
   </View> */}

   <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
   {curr_vehicle_image}   
   </View>
   {/*</View> */}

 
   {/*</TouchableOpacity>*/}

    
<View style={{position: 'absolute', top: 80, left: 0, right: 0, bottom: 0, justifyContent: 'flex-start', alignItems: 'center'}}>
  {/*  <Text>{seconds}</Text>*/} 

 {timer}
    </View>    

    <StatusBar style="auto" />

    </View>
    </ImageBackground>
  );
}
// <Image style={img_style.fitImage} source={curr_vehicle.img_vechile} />


const styles = StyleSheet.create({
    container: {
      flex: 1,
      //backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
 //   fitImage: {
 //     resizeMode: 'contain',
  //    aspectRatio: 0.6,
 //     flex: 1,
//    },
    fitImage_new: {
      resizeMode: 'contain',
      ...Platform.select({
        android: {
          //   width: Dimensions.get('window').width*.96,
             borderRadius: 50 / 2,

        },
        web: {
          flex: 1,
     //      height: undefined,
           aspectRatio: 1,
           shadowColor: '#000',
           shadowOffset: { width: 0, height: 10 },
           shadowOpacity: 0.2,
           shadowRadius: 2,
           elevation: 5,
        }
      })
    },
    // shadow: {

    //   ...Platform.select({
    //     android: {
    //       resizeMode: 'contain',
    //       tintColor: 'rgba(0,0,0,0.2)',
    //    //   flex: 1,
    //     //  width: Dimensions.get('window').width*.98,
    //       borderRadius: 50 / 2,
    //     },
    //     web: {
    //       height:0,
    //       width:0
    //     }
    //   })
    // },
    ContainerImageProfile: {
      margin:8,
      ...Elevations[100000]
      //Properties to setup your Shadow 

//      shadowOffset: { width: 100, height: 100 },
 //     shadowColor: '#000',
 //     shadowOpacity: 10,
 //     elevation: 100,
 //     shadowRadius: 2,
//      backgroundColor : "#000"

  }
  });

export default QuestionScreen;
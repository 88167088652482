import React, { useCallback, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import {  StyleSheet, Text, View, TextInput } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import {useSelector, useDispatch} from 'react-redux';
import { updateVehicle, updateBackground } from '../../controller/gameState/actions/GameStateAction';
import { useState, useRef } from 'react';
import {TextAnimationShake} from 'react-native-text-effects';
import {Input, Button} from 'react-native-elements';
import * as Animatable from 'react-native-animatable';
import { appStyles } from '../styles/app_styles';
import { ImageBackground, InteractionManager } from 'react-native';
import { Icon } from 'react-native-elements';
import { putPlayerScore } from '../../repository/PlayerScoreDAL';
import { en_content } from '../../repository/Contents';




const EndgameScreen = ({ route, navigation }) => {


    const curr_background =  useSelector(state => state.gameState.background_image )
    const curr_score =  useSelector(state => state.gameState.curr_score );
    const content =  useSelector(state => state.gameState.curr_content );

    const [nickname, onChangeNickname] = React.useState("");
    const nameInputRef = useRef(null); 
  //  nameInputRef.current.focus();
    useEffect(()=>
    {
      InteractionManager.runAfterInteractions(() => {
        nameInputRef.current.focus()
      });
    }, []);
    
//     let isBackPressed = true; 



//         useEffect(() => {

//           const unsubscribe = navigation.addListener('beforeRemove', e => {
//             if(isBackPressed){
//             e.preventDefault(); // Prevent default action
//             unsubscribe() // Unsubscribe the event on first call to prevent infinite loop
//             // mulighed ti at flytte isBackPressed her
//             navigation.navigate('Start')} // Navigate to your desired screen
//           });
// }, [])                   

  // const dispatch = useDispatch();
  

  // const continueToNextVehicle = () => {
  //   isBackPressed = false;
  //  toggleHideView(true);   
  //   navigation.navigate('Question');
  //   dispatch(updateVehicle());
  //   dispatch(updateBackground());
  // }

  const endgame = () => {
    putPlayerScore(nickname,curr_score)
   // .then(res =>{console.log("then");    navigation.navigate('Start');})
   // .catch(e=>{console.log("catch");    navigation.navigate('Start');});
   navigation.navigate('Start');
  }

    return(
      <ImageBackground style={ appStyles.imgBackground } 
    resizeMode='cover' 
    source={curr_background.img_path}>

      <View style={styles.container}>
        <Text style={{color: 'black', fontSize: 20, textAlign: 'center' }}>
          <Icon name='trophy' type='font-awesome-5'  color='#FFD700' />
            {content.game_over_screen.congrats}
            <Text style={[curr_score>0?{color:'#90ee90'}:{color:'#bd0000'}, {fontWeight: "bold",  fontSize: 20}]}> {curr_score}</Text> </Text>
            <TextInput
        //   ref={ref=>{
        //     global.nameInput=ref;
        // }}
        ref={nameInputRef}
        // autoFocus={true}
        style={styles.input}
        onChangeText={onChangeNickname}
        value={nickname}
        maxLength={20}
        placeholder={content.game_over_screen.enter_nickname}
        placeholderTextColor= 'white'
        keyboardShouldPersistTaps='always'
      />
      <View style={{marginTop:50}}>
      <Button disabled={nickname.length<1}
              title={content.game_over_screen.accept_btn}
              titleStyle={appStyles.btnTitleStyle}
              buttonStyle={appStyles.buttonStyle}
              onPress={() => endgame()} 
            />   
            </View>
      
      <StatusBar style="auto" />
      </View>
      </ImageBackground>
    );
  /*} else {
    return(
      <HidableView hide={isHiddenView} style={styles.container}>
      <Text> {wrongAnswerText} </Text>
      <Button title='Næste'
              onPress={() => continueToNextVehicle()} /> 
      <StatusBar style="auto" />
      </HidableView>
    );
  }*/


}

//  <Text> {isAnswerCorrect ? trueAnswerText : wrongAnswerText } </Text>

const styles = StyleSheet.create({
    container: {
       position: 'absolute',
     top: 125,
     left: 0,
     right: 0,

      flex: 1,
 //     backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    input: {
      marginTop:30,
      height: 40,
      margin: 12,
      borderWidth: 0.5,
      padding: 10,
      // placeholderTextColor: 'white',
      color:'white'
    },
  });


export default EndgameScreen;
import { StyleSheet } from "react-native";
import { material, robotoWeights } from 'react-native-typography'

export const appStyles = StyleSheet.create({
      imgBackground: {
        width: '100%',
        height: '100%',
        flex: 1 
      },

      btnTitleStyle: {
      //  ...material.display4,
      // ...robotoWeights.medium,
        color: "white",
        fontSize: 24,
      },
      buttonStyle:{
        backgroundColor: "#808C6D",
        borderRadius: 60,
        height: 60,
        width: 160,
        marginBottom: 20,  
      },
  });
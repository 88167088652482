export const dk_content = {
    lang: 'dk',
    btn_play: 'Spil',
    resultsBtn: 'Resultater',
    aboutBtn: 'Om',
    level_1_Btn: 'Trin 1',
    level_2_Btn: 'Trin 2',
    filterPlaceholder: 'Skriv her for at søge...',
    navigator_text : {
      scoreboard_title: 'Top 20 resultater', 
      choose_level_title: 'Vælg trin',
      endgame_title: 'Slut',
      about_title: 'Om',
      feedback_title: 'Tilbagemelding',
      answer_title: 'Svar',
      question_title : 'Spørgsmål'
    },
    feedback_screen : {
      not_correct_text : 'Desværre, det var:',
      actual_score_text: 'Aktuel score: ',
      next_btn: 'Næste',
      finish_btn: 'Afslut',
      feedbacks: ['Lige præcis!',
                  'Godt arbejde!',
                  'Dygtig!',
                  'Fremragende!',
                  'Udmærket!',
                  'Glimrende!']
    },
    game_over_screen : {
      congrats: 'Tillykket med:',
      accept_btn: 'Godkend',
      enter_nickname: 'Indtast dit navn'
    }

  };

export const en_content = {
    lang: 'en',
    btn_play: 'Play',
    resultsBtn: 'Scoreboard',
    aboutBtn: 'About',
    level_1_Btn: 'Level 1',
    level_2_Btn: 'Level 2',
    filterPlaceholder: 'Filter...',
    navigator_text : {
      scoreboard_title: 'Top 20 results', 
      choose_level_title: 'Choose level',
      endgame_title: 'Game over',
      about_title: 'About',
      feedback_title: 'Feedback',
      answer_title: 'Answer',
      question_title : 'Question'
    },
    feedback_screen : {
      not_correct_text : 'Unfortunately, it was:',
      actual_score_text: 'Current score: ',
      next_btn: 'Next',
      finish_btn: 'End game',
      feedbacks: ['Exactly!',
                  'Good job!',
                  'Excellent!',
                  'Fine!',
                  'Outstanding!']
    },
    game_over_screen : {
      congrats: 'Congrats with:',
      accept_btn: 'Accept',
      enter_nickname: 'Enter a nickname'
    }
  };
import React, { useCallback, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import {  StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import {useSelector, useDispatch} from 'react-redux';
import { updateVehicle, updateBackground } from '../../controller/gameState/actions/GameStateAction';
import HidableView from './HidableView';
import { useState } from 'react';
import {TextAnimationShake} from 'react-native-text-effects';
import {Input, Button} from 'react-native-elements';
import * as Animatable from 'react-native-animatable';
import { appStyles } from '../styles/app_styles';
import { ImageBackground } from 'react-native';
import { Icon } from 'react-native-elements';




const ResultScreen = ({ route, navigation }) => {

    const [isHiddenView, toggleHideView] = useState(false);

    const curr_vehicle =  useSelector(state => state.gameState.curr_vehicle );
    const isAnswerCorrect =  useSelector(state => state.gameState.isAnswerCorrect);
    const curr_background =  useSelector(state => state.gameState.background_image )
    const curr_score =  useSelector(state => state.gameState.curr_score );
    const content =  useSelector(state => state.gameState.curr_content );
    

    const trueAnswerText = 'Congrats. You are correct';
    const wrongAnswerText = 'You are wrong. The answer is ' + JSON.stringify(curr_vehicle.name_vehicle) ;
    const animations = ['bounce', 'flash','jello', 'pulse', 'rotate', 'rubberBand', 'shake', 'swing', 'tada', 'wobble']
    const feedbacks =content.feedback_screen.feedbacks
    
    let isBackPressed = true; 

        useEffect(() => {

          const unsubscribe = navigation.addListener('beforeRemove', e => {
            if(isBackPressed){
            e.preventDefault(); // Prevent default action
            unsubscribe() // Unsubscribe the event on first call to prevent infinite loop
            // mulighed ti at flytte isBackPressed her
            navigation.navigate('Start')} // Navigate to your desired screen
          });
}, [])                   

  const dispatch = useDispatch();
  

  const continueToNextVehicle = () => {
    isBackPressed = false;
   toggleHideView(true);   
    navigation.navigate('Question');
    dispatch(updateVehicle());
    dispatch(updateBackground());
  }

  const endgame = () => {
    isBackPressed = false;
   //toggleHideView(true);   
    navigation.navigate('Endgame');
  }
  
  
    return(
      <ImageBackground style={ appStyles.imgBackground } 
    resizeMode='cover' 
    source={curr_background.img_path}>

      <HidableView hide={isHiddenView} style={styles.container}>
     {isAnswerCorrect?  <Animatable.Text animation={animations[getRandomInt(animations.length)]} iterationCount={1} style={{color: '#90ee90',fontWeight: "bold" , fontSize: 40}}>{feedbacks[getRandomInt(feedbacks.length)]}</Animatable.Text>:
     <Text style={{color: 'black', fontSize: 20, textAlign: 'center' }}>{content.feedback_screen.not_correct_text}{"\n"}<Text style={{color:'#bd0000', fontWeight: "bold",  fontSize: 40}}>{JSON.stringify(curr_vehicle.name_vehicle).replace(/['"]+/g, '')}</Text> </Text>}
      
      <View style={{marginTop:40}}>
        <Text style={{color: 'black', fontSize: 20, textAlign: 'center' }}>
          <Icon name='trophy' type='font-awesome-5'  color='#FFD700' />
          {content.feedback_screen.actual_score_text}
            <Text style={[curr_score>0?{color:'#90ee90'}:{color:'#bd0000'}, {fontWeight: "bold",  fontSize: 20}]}> {curr_score}</Text> </Text>
  
      </View>
      <View style={{margin:50}}>
      <Button title={content.feedback_screen.next_btn}
              titleStyle={appStyles.btnTitleStyle}
              buttonStyle={appStyles.buttonStyle}
              onPress={() => continueToNextVehicle()} 
            /> 
      <Button title={content.feedback_screen.finish_btn}
              titleStyle={appStyles.btnTitleStyle}
              buttonStyle={[appStyles.buttonStyle, {backgroundColor:'dimgrey'}]}
              onPress={() => endgame()} 
            />  
            </View>
      
      <StatusBar style="auto" />
      </HidableView>
      </ImageBackground>
    );
  /*} else {
    return(
      <HidableView hide={isHiddenView} style={styles.container}>
      <Text> {wrongAnswerText} </Text>
      <Button title='Næste'
              onPress={() => continueToNextVehicle()} /> 
      <StatusBar style="auto" />
      </HidableView>
    );
  }*/


}

//  <Text> {isAnswerCorrect ? trueAnswerText : wrongAnswerText } </Text>

const styles = StyleSheet.create({
    container: {
      flex: 1,
 //     backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
 }
 
export default ResultScreen;
import { firebase } from '@firebase/app';
// import firestore from '@react-native-firebase/firestore';
import { getFirestore, setDoc, doc } from 'firebase/firestore'; // Somehow crashes if the import is removed
import { firebaseDB } from './FirebaseService';
  
      
  // TODO: If the error occurs, while trying to put a data to firebase, so the function 
  // never returns somethings. That's it continues to POST data in firebase every few seconds, 
  // until e.g. the internet connection reestablished. 
  // The task is find a way to cancel await function and throw exception.
  export const putPlayerScore = async (playerName, playerScore) => {
        // Initialize Firebase
        if(firebaseDB === undefined){
            await initializeFirebase();
        }
  

        // setTimeout(()=> {
        //     console.log("10 seconds gone")
        // }, 10000)
        
        const res =  await firebaseDB.
            collection('playerScores')
            .add({
              playerName: playerName,
              playerScore: playerScore,
            })
        console.log("res");

          //.then(() => {
          //  console.log('User added!');
          //});
      return true;
}    

export const fetchPlayerScores = async () => {
        console.log("fetchPlayerScores is executed")
        const playerScores = [];

        // Initialize Firebase
        if(firebaseDB === undefined){
          await initializeFirebase();
        }
    
        let playerScores_querySnapshot; 
        playerScores_querySnapshot = await firebaseDB.collection('playerScores')
                                    .orderBy('playerScore', 'desc')
                                    .limit(20)
                                    .get();
        
        playerScores_querySnapshot.forEach(documentSnapshot => {
            playerScores.push(documentSnapshot.data());
        }); 

        playerScores.forEach((e,i) => e.place = i+1)

        if(playerScores.length == 0){
                throw Error("Scores couldn't be fetched") 
        }
    
        return playerScores;
      }       
import { firebase } from '@firebase/app';
// import firestore from '@react-native-firebase/firestore';
import { getFirestore, setDoc, doc } from 'firebase/firestore'; // Somehow crashes if the import is removed


const firebaseConfig = {
    apiKey: "AIzaSyD3d5idBbvnGQ2eFw4q75aoqPzuBAHW5no",
    authDomain: "matkend-76800.firebaseapp.com",
    databaseURL: "https://matkend-76800-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "matkend-76800",
    storageBucket: "matkend-76800.appspot.com",
    messagingSenderId: "101962191466",
    appId: "1:101962191466:web:a807f067b30a473096f013",
    measurementId: "G-7ECL32F0Z1"
  };
  

export let firebaseDB; 
  
export default async function initializeFirebase(){
    firebase.initializeApp(firebaseConfig);
    firebaseDB = await firebase.firestore(); 
    return;
} 